import React, { Fragment } from "react";
import Layout from "components/Layout"
import { StaticQuery, graphql } from "gatsby"
import Header from 'components/Header'
import Seo from "components/Seo"
import {
  // ImageBoxBottom,
  // ImageBoxLeft,
  ImageBoxRight
} from 'components/ImageBox'
import CardSection from 'components/CardSection'
import EmailIcon from '@material-ui/icons/MailOutline'
import HomeIcon from '@material-ui/icons/HomeOutlined'

const buttons = [
  {
    text: 'Request a quote',
    to: '/contact',
    icon: <EmailIcon />
  },
  {
    text: 'Return to home',
    to: '/',
    icon: <HomeIcon />
  },
]

function About(props) {
  return (
    <Fragment>
      <StaticQuery
        query={graphql`
        query {
          site {
           siteMetadata {
            description
             title
           }
        }
          image1: file(relativePath: { eq: "img/general/jack-ute.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          
          about: markdownRemark(frontmatter: { name: { eq: "about" } }) {
            html
            frontmatter {
              title
            }
          }
          mission: markdownRemark(frontmatter: { name: { eq: "mission" } }) {
            html
            frontmatter {
              title
            }
          }
        }
      `}
        render={data => (
          <Layout>
            <Seo
              title={'About ' + data.site.siteMetadata.title}
              description={data.site.siteMetadata.homepageAbout}
            />
            <Header
              title={"About " + data.site.siteMetadata.title}
              subtitle={data.site.siteMetadata.description}
            />
            <CardSection
              buttons={buttons}
              scheme2
            >
              <ImageBoxRight
                align="left"
                title={data.mission.frontmatter.title}
                image={data.image1.childImageSharp.fluid}
                body={data.mission.html}
                scheme2
              />
            </CardSection>
          </Layout>
        )}
      />
    </Fragment>
  )
}

export default About