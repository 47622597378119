import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    title: {
        fontSize: '2rem',
        fontWeight: 700,
        color: theme.palette.secondary.main,
        background: theme.palette.background.default,
        padding: theme.spacing(4, 0)
    },
    subtitle: {
        fontSize: '1.4rem',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.default,
        padding: theme.spacing(3, 0)
    }
}));

function Header(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} component="h1">{props.title}</Typography>
            {/* <Typography className={classes.subtitle} component="h2">{props.subtitle}</Typography> */}
        </div>
    )
}

export default Header