import * as React from "react"
import Img from 'gatsby-image'
import ArticleBody from 'components/ArticleBody'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0),
    height: 'auto',
    width: '100%',
  },
  text: {
    margin: theme.spacing(0),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    },
  },
  image: {
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(4, 0),
    fontSize: '2.1rem',
    fontWeight: 600,
  },
}));

function ImageBoxLeft(props) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={0}>
      {props.title && (
        <Grid item xs={12}>
          <Typography color="primary" align="center" className={classes.title} component="h2" variant="h2">{props.title}</Typography>
        </Grid>
      )}
      <Grid component={Img} fluid={props.image} className={classes.image} item xs={12} sm={6} />
      <Grid className={classes.text} style={{ background: props.scheme2 ? theme.palette.scheme2.card : theme.palette.scheme1.card }} item xs={12} sm={6}>
        <ArticleBody key={props.key} variant={props.variant} align={props.align} className={classes.text} content={props.body} />
      </Grid>
    </Grid>
  )
}

function ImageBoxRight(props) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={0}>
      {props.title && (
        <Grid item xs={12}>
          <Typography color="primary" align="center" className={classes.title} component="h2" variant="h2">{props.title}</Typography>
        </Grid>
      )}
      <Grid className={classes.text} style={{ background: props.scheme2 ? theme.palette.scheme2.card : theme.palette.scheme1.card }} item xs={12} sm={6}>
        <ArticleBody key={props.key} variant={props.variant} align={props.align} className={classes.text} content={props.body} />
      </Grid>
      <Grid component={Img} fluid={props.image} className={classes.image} item xs={12} sm={6} />
    </Grid>
  )
}

function ImageBoxBottom(props) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={0}>
      {props.title && (
        <Grid item xs={12}>
          <Typography color="primary" align="center" className={classes.title} component="h2" variant="h2">{props.title}</Typography>
        </Grid>
      )}
      <Grid className={classes.text} style={{ background: props.scheme2 ? theme.palette.scheme2.card : theme.palette.scheme1.card }} item xs={12}>
        <ArticleBody key={props.key} variant={props.variant} align={props.align} className={classes.text} content={props.body} />
      </Grid>
      <Grid component={Img} fluid={props.image} className={classes.image} item xs={12} />
    </Grid>
  )
}

export { ImageBoxLeft, ImageBoxRight, ImageBoxBottom }